import request from '@/utils/request'

// 明细账科目
export function getSubjectDetailHis(data) {
  return request({
    url: 'api/v2/old/getSubjectDetailHis',
    method: 'post',
    data
  })
}
// 明细账列表
export function getHisDetailAccountList(data) {
  return request({
    url: 'api/v2/old/getHisDetailAccountList',
    method: 'post',
    data
  })
}
// 凭证汇总
export function bookAllVoucherListHis(data) {
  return request({
    url: 'api/v2/old/bookAllVoucherListHis',
    method: 'post',
    data
  })
}
// 总账
export function allAccountListNew(data) {
  return request({
    url: 'api/v2/old/allAccountListNew',
    method: 'post',
    data
  })
}

// 科目列表
export function hisSubjectBalance(data) {
  return request({
    url: 'api/v2/old/hisSubjectBalance',
    method: 'post',
    data
  })
}
// 科目列表绑定科目
export function getOldSubjectBind(data) {
  return request({
    url: 'api/v2/old/getOldSubjectBind',
    method: 'post',
    data
  })
}
// 科目列表绑定科目保存
export function saveOldSubjectBind(data) {
  return request({
    url: 'api/v2/old/saveOldSubjectBind',
    method: 'post',
    data
  })
}
// 凭证列表
export function hisVoucherList(data) {
  return request({
    url: 'api/v2/old/hisVoucherList',
    method: 'post',
    data
  })
}
// 删除凭证
export function delHisVoucher(data) {
  return request({
    url: 'api/v2/old/delHisVoucher',
    method: 'post',
    data
  })
}
// 修改凭证列表
export function hisVoucherInfo(data) {
  return request({
    url: 'api/v2/old/hisVoucherInfo',
    method: 'post',
    data
  })
}
// 修改凭证科目列表
export function hisSubjectList(data) {
  return request({
    url: 'api/v2/old/hisSubjectList',
    method: 'post',
    data
  })
}
// 保存凭证列表
export function saveHisVoucher(data) {
  return request({
    url: 'api/v2/old/saveHisVoucher',
    method: 'post',
    data
  })
}

// 保存凭证列表
export function importVoucherHis(data) {
  return request({
    url: 'api/v2/old/importVoucherHis',
    method: 'post',
    data
  })
}

// 保存凭证列表
export function importHisSubjectFall(data) {
  return request({
    url: 'api/v2/old/importHisSubjectFall',
    method: 'post',
    data
  })
}

//资产负债表
export function hisZcfzInfo(data) {
  return request({
    url: 'api/v2/old/hisZcfzInfo',
    method: 'post',
    data
  })
}
//利润表(季报)
export function hisLrbInfo(data) {
  return request({
    url: 'api/v2/old/hisLrbInfo',
    method: 'post',
    data
  })
}
//利润表(月报)
export function hisLrbMonthInfo(data) {
  return request({
    url: 'api/v2/old/hisLrbMonthInfo',
    method: 'post',
    data
  })
}
//会计报表
export function hisKjSave(data) {
  return request({
    url: 'api/v2/old/hisKjSave',
    method: 'post',
    data
  })
}
//会计科目
export function hisSubjectTreeList(data) {
  return request({
    url: 'api/v2/old/hisSubjectTreeList',
    method: 'post',
    data
  })
}
//历史会计科目新增修改
export function addOneHisSubject(data) {
  return request({
    url: 'api/v2/old/addOneHisSubject',
    method: 'post',
    data
  })
}

//历史会计科目删除
export function delOldSubjectName(data) {
  return request({
    url: 'api/v2/old/delOldSubjectName',
    method: 'post',
    data
  })
}

//匹配历史科目
export function oldMatchSubject(data) {
  return request({
    url: 'api/v2/old/oldMatchSubject',
    method: 'post',
    data
  })
}

//匹配科目all
export function oldMatch(data) {
  return request({
    url: 'api/v2/old/oldMatch',
    method: 'post',
    data
  })
}

//匹配(创建科目保存)
export function oldSubjectSaves(data) {
  return request({
    url: 'api/v2/old/oldSubjectSaves',
    method: 'post',
    data
  })
}

export function oldTongbuSubject(data) {
  return request({
    url: 'api/v2/old/oldTongbuSubject',
    method: 'post',
    data
  })
}

export function oldNewSubjectSync(data) {
  return request({
    url: 'api/v2/old/oldNewSubjectSync',
    method: 'post',
    data
  })
}

// 批量匹配
export function robotMatchSubject(data) {
  return request({
    url: 'api/v2/old/robotMatchSubject',
    method: 'post',
    data
  })
}

export function robotMatch(data) {
  return request({
    url: 'api/v2/old/robotMatch',
    method: 'post',
    data
  })
}