<template>
  <el-select style="width:100%;border: 0;" filterable v-model="id" placeholder="请选择">
    <el-option 
    v-for="(itemcode,index) in subjectList" 
    :key="index" 
    :label="`${itemcode.code}-${itemcode.name2}`" 
    :value="itemcode.id"></el-option>
  </el-select>
</template>
<script>
import { hisSubjectList} from "@/api/old.js";
export default {
  props:{
    subjectId: {
      type: Number,
      default: 0
    },
    period:{
      type:String,
      default:''
    }
  },
  created() {
    this.getList()
  },
  data(){
    return {
      subjectList: [],
    }
  },
  computed: {
    id: {
      get() {
        if(!this.subjectId){
          return ""
        }
        return this.subjectId
      },
      set(val) {
        this.$emit("update:subjectId", val * 1)
        this.$emit("success", val * 1)
      }
    }
  },
  methods: {
    getList(){
      hisSubjectList({period:this.period}).then(res => {
        this.subjectList = res.data.data.list
      });
    }
      
  }
}
</script>